import { BackendMethod } from '@memberspot/shared/model/backend';

import { ResetExamRunDto } from './dtos/exam-results.dto';
import { ExamNewDto, UpdateExamQuestionDto } from './dtos/new-exam.dto';

export const ExamManageEndpointsV2 = {
  GET_ALL_EXAMS: (schoolId: string) => ({
    url: `/exam-manage/all/${schoolId}`,
    method: BackendMethod.GET,
  }),
  GET_EXAM: (schoolId: string, examId: string) => ({
    url: `/exam-manage/${schoolId}/${examId}`,
    method: BackendMethod.GET,
  }),
  GET_EXAM_BY_ID: (schoolId: string, id: string) => ({
    url: `/exam-manage/id/${schoolId}/${id}`,
    method: BackendMethod.GET,
  }),
  CREATE_EXAM: (data: ExamNewDto) => ({
    url: `/exam-manage/${data.schoolId}`,
    method: BackendMethod.POST,
    data,
  }),
  DELETE_EXAM: (schoolId: string, examId: string) => ({
    url: `/exam-manage/${schoolId}/${examId}`,
    method: BackendMethod.DELETE,
  }),
  DUPLICATE_EXAM: (schoolId: string, examId: string) => ({
    url: `/exam-manage/duplicate/${schoolId}/${examId}`,
    method: BackendMethod.PUT,
  }),
  UPDATE_EXAM_SETTINGS: (data: ExamNewDto) => ({
    url: `/exam-manage/update-properties/${data.schoolId}/${data.examId}`,
    method: BackendMethod.PATCH,
    data,
  }),
  UPDATE_EXAM_QUESTIONS: (data: UpdateExamQuestionDto) => ({
    url: `/exam-manage/update-questions/${data.schoolId}/${data.examId}`,
    method: BackendMethod.PATCH,
    data,
  }),
};

export const ExamManageResultEndpoints = {
  GET_RESULT: (schoolId: string, examId: string, uid: string) => ({
    url: `/exam-result/${schoolId}/${examId}/${uid}`,
    method: BackendMethod.GET,
  }),
  GET_RESULTS: (schoolId: string, uid: string) => ({
    url: `/exam-result/${schoolId}/${uid}`,
    method: BackendMethod.GET,
  }),
  RESET_COUNT: (data: ResetExamRunDto) => ({
    url: `/exam-result/reset-count/${data.schoolId}/${data.examId}/${data.uid}`,
    method: BackendMethod.PATCH,
    data,
  }),
};
