import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { ExamBaseNew } from '@memberspot/shared/model/exam';

export interface AllExamsState
  extends EntityState<ExamBaseNew, string>,
    ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'allExams', idKey: 'examId' })
export class AllExamsStore extends EntityStore<AllExamsState> {
  constructor() {
    super();
  }
}
